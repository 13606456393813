import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const cadenasOptions = [
    { value: 'SAMS', label: 'SAMS', color: '#00B8D9' },
    { value: 'ARTELI', label: 'ARTELI', color: '#0052CC' },
    { value: 'BODEGA AURRERA TP', label: 'BODEGA AURRERA TP', color: '#5243AA' },
    { value: 'CHEDRAUI', label: 'CHEDRAUI', color: '#FF5630' },
    { value: 'CITY FRESKO', label: 'CITY FRESKO', color: '#FF8B00' },
    { value: 'GARIS', label: 'GARIS', color: '#FFC400' },
    { value: 'HEB', label: 'HEB', color: '#36B37E' },
    { value: 'OXXO', label: 'OXXO', color: '#00875A' },
    { value: 'SORIANA', label: 'SORIANA', color: '#253858' },
    { value: 'SUPERAMA TP', label: 'SUPERAMA TP', color: '#666666' },
    { value: 'SUPERCENTER TP', label: 'SUPERCENTER TP', color: '#00B8D9' },
];

// export const middlewareApiServiceUrl = 'http://localhost:5000';
export const middlewareApiServiceUrl = 'https://v2.retailanalyzer.com.mx:5000';

export const usersApiServiceUrl = 'https://v2.retailanalyzer.com.mx:5001';

export const repoApiServiceUrl = 'https://repo.retailanalyzer.com.mx';

export const getResponseData = (obj, single = false) => {
    let result;
    try {
        if (single)
            result = obj?.data?.data[0];
        else
            result = obj?.data?.data;
    } catch (error) {
        console.error('Cant obtain response data');
    }
    return result;
};

export const getPercentWeek = (num) => {
    let result = 0;
    try {
        result = (num / MAX_SEMANAS * 100).toFixed(2)
    } catch (error) {
        console.error(`Cant transform the num ${num} to percent`);
    }
    return result;
}

export const MAX_SEMANAS = 52;

export const fixNumericData = (data, aomuntFields = [], numericFields = []) => {
    let result = [];
    try {
        data.forEach(row => {
            aomuntFields.forEach(field => {
                row[field] = `$${parseInt(row[field].toFixed(0)).toLocaleString()}`;
            });
            numericFields.forEach(field => {
                row[field] = row[field].toLocaleString();
            });
            result.push(row);
        });
    } catch (error) {
        console.error(`Cant boild custom numeric data`);
        result = data;
    }
    return result;
}

export const COLOR_PALETTE = ['#8ECAE6', '#219EBC', '#023047', '#FFB703', '#FB8500'];

// V3 COLOR PALETTE
export const DEMO_COLOR_PALETTE = [
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',];
export const REVERSE_DEMO_COLOR_PALETTE = [
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',];
export const REVERSE_COLOR_PALETTE = [
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',
];
export const REVERSE_COLOR_PALETTE2 = [
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',
    '#0a3856',
    '#0d47a1',
    '#067dc7',
    '#4471a4',
    '#779faf',
    '#5fb1ec',
    '#91d6e8',
    '#034f2b',
    '#007d44',
    '#5d9732',
    '#666610',
    '#8c8c0a',
    '#b5be03',
    '#b0d45d',
    '#b44d1c',
    '#cc651f',
    '#f57921',
    '#fc9725',
    '#fbb110',
    '#f7cd5e',
    '#f2e05a',
    '#ffe700',
];

export const REVERSE_COLOR_PALETTE_WHITE_SHADOW = [
    '#1b3a50',
    '#345a92',
    '#4793c1',
    '#6a829d',
    '#93aeb8',
    '#8cbfe2',
    '#aedeea',
    '#23553e',
    '#398764',
    '#6f9453',
    '#777732',
    '#90903d',
    '#b9bf51',
    '#bbd57f',
    '#b87556',
    '#dd9362',
    '#f4a166',
    '#ebaa60',
    '#f0c15d',
    '#fadd8d',
    '#f4e88e',
    '#efe47d',
];
export const REVERSE_COLOR_PALETTE_WHITE_SHADOW2 = [
    '#1b3a50',
    '#345a92',
    '#4793c1',
    '#6a829d',
    '#93aeb8',
    '#8cbfe2',
    '#aedeea',
    '#23553e',
    '#398764',
    '#6f9453',
    '#777732',
    '#90903d',
    '#b9bf51',
    '#bbd57f',
    '#b87556',
    '#dd9362',
    '#f4a166',
    '#ebaa60',
    '#f0c15d',
    '#fadd8d',
    '#f4e88e',
    '#efe47d',
    '#1b3a50',
    '#345a92',
    '#4793c1',
    '#6a829d',
    '#93aeb8',
    '#8cbfe2',
    '#aedeea',
    '#23553e',
    '#398764',
    '#6f9453',
    '#777732',
    '#90903d',
    '#b9bf51',
    '#bbd57f',
    '#b87556',
    '#dd9362',
    '#f4a166',
    '#ebaa60',
    '#f0c15d',
    '#fadd8d',
    '#f4e88e',
    '#efe47d',];
export const REVERSE_COLOR_PALETTE_SHORT = [
    '#0063a6',
    '#1f86aa',
    '#29abe2',
    '#c9c552',
    '#5d9732',
    '#50a470',
];
export const REVERSE_COLOR_PALETTE_SHORT2 = [
    '#0063a6',
    '#1f86aa',
    '#29abe2',
    '#c9c552',
    '#5d9732',
    '#50a470',
    '#0063a6',
    '#1f86aa',
    '#29abe2',
    '#c9c552',
    '#5d9732',
    '#50a470',
];
export const REVERSE_COLOR_PALETTE_SHORT_WHITE_SHADOW = [
    '#4582ab',
    '#1f86aa',
    '#66b4d5',
    '#d3d194',
    '#708f58',
    '#6ca883',
];
export const REVERSE_COLOR_PALETTE_SHORT_WHITE_SHADOW2 = [
    '#4582ab',
    '#1f86aa',
    '#66b4d5',
    '#d3d194',
    '#708f58',
    '#6ca883',
    '#4582ab',
    '#1f86aa',
    '#66b4d5',
    '#d3d194',
    '#708f58',
    '#6ca883',
];
export const REVERSE_COLOR_PALETTE_TINY = [
    '#0063a6',
    '#91d6e8',
    '#5d9732',
];
export const REVERSE_COLOR_PALETTE_TINY2 = [
    '#0063a6',
    '#91d6e8',
    '#5d9732',
    '#0063a6',
    '#91d6e8',
    '#5d9732',
];
export const REVERSE_COLOR_PALETTE_TINY2_TO_STACKED = [
    '#61b0ec',
    '#839aa1',
];
export const REVERSE_COLOR_PALETTE_TINY_WHITE_SHADOW = [
    '#3f85b3',
    '#a8d3dd',
    '#7a9466',
];
export const REVERSE_COLOR_PALETTE_TINY_WHITE_SHADOW2 = [
    '#3f85b3',
    '#a8d3dd',
    '#7a9466',
    '#3f85b3',
    '#a8d3dd',
    '#7a9466',
];
export const REVERSE_COLOR_PALETTE_MEDIUM = [
    '#777b7c',
    '#839aa1',
    '#0662a2',
    '#61b0ec',
];
export const COLOR_PALETTE_MEDIUM = [
    '#61b0ec',
    '#0662a2',
    '#839aa1',
    '#777b7c',
];
export const INVENTORY_COLOR_PALETTE_PRIMARY = [
    '#a2cd40',
    '#d8052a',
    '#fc8503',
    '#17a589',
    '#fbd614',
];
export const INVENTORY_COLOR_PALETTE_SECONDARY = [
    '#f6c343',
    '#44a2e8',
    '#225894',
    '#a2cd40',
];

export const CUSTOM_TABLE_COLOR_PALETTE = {
    footer: {
        backgroundColor: '#839aa1',
        color: '#ffffff',
        borderLeft: '#61b0ec solid 1px',
        borderRight: '#61b0ec solid 1px'
    },
    header: {
        backgroundColor: '#61b0ec',
        color: '#ffffff',
        borderLeft: '#61b0ec solid 1px',
        borderRight: '#61b0ec solid 1px'
    },
    border: {
        borderColor: '#61b0ec',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    font: {
        fontWeight: 'bold'
    },
    width: "(100/x)%"
};
// V3 COLOR PALETTE

export const NOT_ASSIGNED_LABEL = 'SIN ASIGNAR';

export const NO_OPTIONS_LABEL = 'Sin opciones';

export const SELECT_LABEL = 'Selecciona...';

export const OPEN_ICON = '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" stroke-width="1.5" viewBox="0 0 24 24" color="#6e8192"><path stroke="#6e8192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="m20 20-5-5m0 0v4m0-4h4M4 20l5-5m0 0v4m0-4H5M20 4l-5 5m0 0V5m0 4h4M4 4l5 5m0 0V5m0 4H5"></path></svg>';
export const CLOSE_ICON = '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" stroke-width="1.5" viewBox="0 0 24 24" color="#6e8192"><path stroke="#6e8192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M7 4H4v3M17 4h3v3M7 20H4v-3M17 20h3v-3"></path></svg>';
export const HORIZONTAL_EXPAND_ICON = `<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15 17L20 12L15 7M9 7L4 12L9 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`;
export const HORIZONTAL_CONTRACT_ICON = `<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15 17L20 12L15 7M9 7L4 12L9 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`;


export const EXTERNALS_USERS_TO_NOTIFY = ['enrosalese@bepensa.com', 'fernando.acosta@madrilena.com.mx'];

export const validateOnProcessDateRange = (date, initRange, endRange) => {
    let isValid = false;
    try {
        date = parseInt(`${date}`);
        initRange = parseInt(`${initRange}`);
        endRange = parseInt(`${endRange}`);
        isValid = !(date >= initRange && date <= endRange);
    } catch (error) {
        console.error(`Cant validate date`);
    }
    return isValid;

}

export const DEFAULT_LAYOUT_HEADER = {
    "idProyectoCadena": "",
    "Cadena": "",
    "Razón Social": "",
    "Número Tienda Real": "",
    "Nombre Tienda": "",
    "UPC": "",
    "Código Interno Cliente": "",
    "Ítem": "",
    "Nombre Producto": "",
    "Venta Unidades": "",
    "Venta Pesos": "",
    "Inventario Unidades": "",
    "Inventario Pesos": "",
    "Inventario Tránsito  Unidades": "",
    "Inventario Tránsito  Pesos": "",
    "Inventario Warehouse Unidades": "",
    "Inventario Warehouse Pesos": "",
    "Inventario on order Unidades": "",
    "Inventario on order Pesos": "",
    "fecha": ""
};

export const DEFAULT_MANDATORY_LABEL = 'OBLIGATORIO';

export const DEFAULT_PREFERENCY_LABEL = 'PREFERENTE';

export const setMandatoryLayoutFields = (client, isLm = false) => {
    let result = {};
    try {
        result = {
            ...client,
            "UPC": "",
            "Código Interno Cliente": "",
            "Ítem": "",
            // "idProyectoCadena": DEFAULT_MANDATORY_LABEL,
            "Número Tienda Real": DEFAULT_MANDATORY_LABEL,
            "Nombre Tienda": DEFAULT_MANDATORY_LABEL,
            "Nombre Producto": DEFAULT_MANDATORY_LABEL,
            "fecha": DEFAULT_MANDATORY_LABEL
        };
        const cadena = client.Cadena.toLowerCase();
        switch (cadena) {
            case 'grupo rivera':
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case '7eleven':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'bodegas alianza':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'calimax':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'casa ley':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'chedraui':
                result["UPC"] = `${DEFAULT_PREFERENCY_LABEL}, en caso de no contar con el valor de "Código Interno Cliente", de lo contrario debe ir vacío`;
                result["Código Interno Cliente"] = `${DEFAULT_PREFERENCY_LABEL}, en caso de no contar con el valor de "UPC", de lo contrario debe ir vacío`;
                break;
            case 'city fresko':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'garis':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'heb':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'oxxo':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'sams':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'soriana':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'futurama':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'liverpool':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'palacio de hierro':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_PREFERENCY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'la europea':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'supercenter tp':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'bodega aurrera tp':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'superama tp':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'casa vargas':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'grupo duero':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'san francisco de asis':
            case 'super aki':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                if (isLm)
                    result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'productos de consumo z':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'costco':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
        }
    } catch (error) {
        console.error(`Cant map mandatory fields to ${client.Cadena}`);
        console.error(error);
        result = client;
    }
    return result;
}

export const dividirString_ = (name) => {
    let arrayNombres = [];
    let string1 = "";
    let string2 = "";
    let middle = name.length / 2;
    if (name.length % 2 !== 0) middle = middle - .5;
    for (let i = 0; i < middle; i++) { string1 += name[i]; }
    for (let i = middle; i < name.length; i++) { string2 += name[i]; }
    arrayNombres.push(name.includes(' ') ? string1 : name);
    arrayNombres.push(name.includes(' ') ? string2 : name);
    return arrayNombres;
}

export const dividirString = (name) => {
    let arrayNombres = [];
    let string1 = "";
    let string2 = "";
    let names = name.split(' ');
    let limit = names.length;
    let middle = limit / 2;
    if (limit % 2 !== 0) middle = middle + .5;
    for (let i = 0; i < middle; i++) string1 += `${names[i]}${i + 1 === middle ? '' : ' '}`;
    for (let i = middle; i < limit; i++) string2 += `${names[i]}${i + 1 === limit ? '' : ' '}`;
    arrayNombres.push(name.includes(' ')
        ? string1
        : name);
    arrayNombres.push(name.includes(' ')
        ? string2 === string1
            ? ''
            : string2
        : '');
    return arrayNombres;
}

export const monthRangeValidation = (dates, today) => {
    let isMonthlyRange = false;
    let { init, end } = dates;
    let endMonthsOptions = ['0131', '0331', '0531', '0731', '0831', '1031', '1231',
        '0430', '0630', '0930', '1130',
        '0228', '0229'];
    if (init
        && end
        && init.length === 8
        && end.length === 8) {
        if (init.endsWith('01'))
            if (endMonthsOptions.find(found => end.endsWith(found))
                && end !== today.toString()) isMonthlyRange = true;
    }
    return isMonthlyRange;
};

export const armarheaderTableDinamica = (selectedDetailFields = [], filtroImpulsor = []) => {
    let headerTableDinamic = [];
    let groupFieldsDinamic = [];
    if (selectedDetailFields.includes('Canal')) {
        headerTableDinamic.push({
            Header: "Canal",
            accessor: "canalCliente",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("canalCliente");
    }
    if (selectedDetailFields.includes('Subcanal')) {
        headerTableDinamic.push({
            Header: "Subcanal",
            accessor: "subcanal",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("subcanal");
    }
    if (selectedDetailFields.includes('Cliente')) {
        headerTableDinamic.push({
            Header: "Cliente",
            accessor: "grupo",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("grupo");
    }
    if (selectedDetailFields.includes('Formato')) {
        headerTableDinamic.push({
            Header: "Formato",
            accessor: "formato",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("formato");
    }
    if (selectedDetailFields.includes('No Tienda')) {
        headerTableDinamic.push({
            Header: "No Tienda",
            accessor: "determinante",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("determinante");
    }
    if (selectedDetailFields.includes('Determinante')) {
        headerTableDinamic.push({
            Header: "No Tienda",
            accessor: "idTiendaReal",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("idTiendaReal");
    }
    if (selectedDetailFields.includes('Tienda') && filtroImpulsor.length <= 0) {
        headerTableDinamic.push({
            Header: "Tienda",
            accessor: "tienda",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("tienda");
    }
    if (selectedDetailFields.includes('Región')) {
        headerTableDinamic.push({
            Header: "Región",
            accessor: "region",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("region");
    }
    if (selectedDetailFields.includes('Área')) {
        headerTableDinamic.push({
            Header: "Área Nielsen",
            accessor: "areanielsen",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("areanielsen");
    }
    if (selectedDetailFields.includes('Estado')) {
        headerTableDinamic.push({
            Header: "Estado",
            accessor: "estado",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("estado");
    }
    if (selectedDetailFields.includes('Ciudad')) {
        headerTableDinamic.push({
            Header: "Ciudad",
            accessor: "ciudad",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("ciudad");
    }
    if (selectedDetailFields.includes('Coordinador')) {
        headerTableDinamic.push({
            Header: "Coordinador",
            accessor: "coordinador",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("coordinador");
    }
    if (selectedDetailFields.includes('Gerente')) {
        headerTableDinamic.push({
            Header: "Coordinador",
            accessor: "gerente",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("gerente");
    }
    if (selectedDetailFields.includes('Supervisor')) {
        headerTableDinamic.push({
            Header: "Supervisor",
            accessor: "supervisor",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("supervisor");
    }
    if (selectedDetailFields.includes('Promotor')) {
        headerTableDinamic.push({
            Header: "Promotor",
            accessor: "promotor",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("promotor");
    }
    if (selectedDetailFields.includes('Tipo Promotoría')) {
        headerTableDinamic.push({
            Header: "Tipo Promotoría",
            accessor: "tipoPromotoria",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("tipoPromotoria");
    }
    if (selectedDetailFields.includes('Ruta')) {
        headerTableDinamic.push({
            Header: "Ruta",
            accessor: "ruta",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("ruta");
    }
    if (selectedDetailFields.includes('Impulsor') && filtroImpulsor.length <= 0) {
        headerTableDinamic.push({
            Header: "Impulsor",
            accessor: "impulsor",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("impulsor");
    }
    if (selectedDetailFields.includes('Representado')) {
        headerTableDinamic.push({
            Header: "Representado",
            accessor: "representado",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("representado");
    }
    if (selectedDetailFields.includes('Categoria')) {
        headerTableDinamic.push({
            Header: "Categoria",
            accessor: "categoria",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("categoria");
    }
    if (selectedDetailFields.includes('Subcategoria')) {
        headerTableDinamic.push({
            Header: "Subcategoria",
            accessor: "subcategoria",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("subcategoria");
    }
    if (selectedDetailFields.includes('Línea')) {
        headerTableDinamic.push({
            Header: "Línea",
            accessor: "linea",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("linea");
    }
    if (selectedDetailFields.includes('Marca')) {
        headerTableDinamic.push({
            Header: "Marca",
            accessor: "marca",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("marca");
    }
    if (selectedDetailFields.includes('Submarca')) {
        headerTableDinamic.push({
            Header: "Submarca",
            accessor: "submarca",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("submarca");
    }
    if (selectedDetailFields.includes('SKU')) {
        headerTableDinamic.push({
            Header: "SKU",
            accessor: "upc",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("upc");
    }
    if (selectedDetailFields.includes('UPC')) {
        headerTableDinamic.push({
            Header: "SKU",
            accessor: "upc",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("upc");
    }
    if (selectedDetailFields.includes('Nombre Global')) {
        headerTableDinamic.push({
            Header: "Nombre Global",
            accessor: "nombreGlobal",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("nombreGlobal");
    }
    if (selectedDetailFields.includes('Código Interno')) {
        headerTableDinamic.push({
            Header: "Código Interno",
            accessor: "codcliente1",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("codcliente1");
    }
    if (selectedDetailFields.includes('Código Dynamics')) {
        headerTableDinamic.push({
            Header: "Código Dynamics",
            accessor: "codcliente2",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("codcliente2");
    }
    if (selectedDetailFields.includes('Producto')) {
        headerTableDinamic.push({
            Header: "Producto",
            accessor: "producto",
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
        });
        groupFieldsDinamic.push("producto");
    }
    return {
        headerTableDinamic,
        groupFieldsDinamic
    }
}

export const handleDownloadCompleteTable = (data = [], name = 'file', header) => {
    if (data?.length > 0) {
        const worksheet = header
            ? XLSX.utils.json_to_sheet(data, { header })
            : XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, `${name}.xlsx`);
    } else
        alert('La descarga no está disponible');
}

export const handleDownloadCurrentTablePageViewById = (id) => {
    if (id) {
        const element = document.getElementById(id);
        element.click();
    } else
        alert('La descarga no está disponible');
}

export const getClientInfo = () => {
    let info = {};
    try {
        info = {

            timeOpened: new Date(),
            timezone: (new Date()).getTimezoneOffset() / 60,

            pageon: window.location.pathname || 'N/A',
            referrer: document.referrer || 'N/A',

            browserName: navigator.appName || 'N/A',
            browserEngine: navigator.product || 'N/A',
            browserVersion1a: navigator.appVersion || 'N/A',
            browserVersion1b: navigator.userAgent || 'N/A',
            browserLanguage: navigator.language || 'N/A',
            browserOnline: navigator.onLine || 'N/A',
            browserPlatform: navigator.platform || 'N/A',
            javaEnabled: navigator.javaEnabled() || 'N/A',
            dataCookiesEnabled: navigator.cookieEnabled || 'N/A',
            dataCookies1: document.cookie || 'N/A',
            dataCookies2: decodeURIComponent(document.cookie.split(";")) || 'N/A',
            dataStorage: localStorage || 'N/A',

            sizeDocW: document.width || 'N/A',
            sizeDocH: document.height || 'N/A',


        };
    } catch (error) {
        console.error(error);
    }
    return info;
}

export const searchInArray = (itemSearch, array = []) => {
    let estatusSelected = array.find(found => found === itemSearch);
    return estatusSelected !== undefined;
}
export const monthsOfYear = () => {
    return [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];
}