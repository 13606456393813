import React, { Suspense, Fragment, lazy } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';
const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Element props={true} />}</Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

export const routes = [
  {
    exact: 'true',
    guard: GuestGuard,
    path: '/auth/signin',
    element: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: 'true',
    path: '/404',
    element: lazy(() => import('./views/errors/NotFound404'))
  },
  {
    exact: 'true',
    path: '/maintenance/coming-soon',
    element: lazy(() => import('./views/maintenance/ComingSoon'))
  },
  {
    exact: 'true',
    path: '/maintenance/error',
    element: lazy(() => import('./views/maintenance/Error'))
  },
  {
    exact: 'true',
    path: '/maintenance/offline-ui',
    element: lazy(() => import('./views/maintenance/OfflineUI'))
  },
  {
    exact: 'true',
    path: '/auth/signup-1',
    element: lazy(() => import('./views/auth/signup/SignUp1'))
  },
  {
    exact: 'true',
    path: '/auth/signup-2',
    element: lazy(() => import('./views/auth/signup/SignUp2'))
  },
  {
    exact: 'true',
    path: '/auth/signin-1',
    element: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: 'true',
    path: '/auth/signin-2',
    element: lazy(() => import('./views/auth/signin/SignIn2'))
  },
  {
    exact: 'true',
    path: '/auth/reset-password-1',
    element: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  },
  {
    exact: 'true',
    path: '/auth/reset-password-2',
    element: lazy(() => import('./views/auth/reset-password/ResetPassword2'))
  },
  {
    exact: 'true',
    path: '/auth/change-password',
    element: lazy(() => import('./views/auth/ChangePassword'))
  },
  {
    exact: 'true',
    path: '/auth/profile-settings',
    element: lazy(() => import('./views/auth/ProfileSettings'))
  },
  {
    exact: 'true',
    path: '/auth/tabs-auth',
    element: lazy(() => import('./views/auth/TabsAuth'))
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: 'true',
        path: '/app/dashboard/inventory',
        element: lazy(() => import('./views/dashboard/DashInventarios'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/ventas',
        element: lazy(() => import('./views/dashboard/DashVentas'))
      },
      {
        exact: 'true',
        path: '/app/reportes/tablero-comercial',
        element: lazy(() => import('./views/dashboard/DashNuevoVentas'))
      },
      {
        exact: 'true',
        path: '/app/reportes/analisis-cobertura',
        element: lazy(() => import('./views/dashboard/DashAnalisisCobertura'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/promotoria',
        element: lazy(() => import('./views/dashboard/DashPromotoria/'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/salud-inventarios',
        element: lazy(() => import('./views/dashboard/DashSaludInventarios'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/venta-cero',
        element: lazy(() => import('./views/dashboard/DashVentaCero'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/disponibilidad-inventarios',
        element: lazy(() => import('./views/dashboard/DashDispInventarios'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/scorecard-VSPT',
        element: lazy(() => import('./views/dashboard/DashScorecardVspt'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/general',
        element: lazy(() => import('./views/dashboard/DashGerencial'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/general',
        // element: lazy(() => import('./views/dashboard/DashGeneral'))
        element: lazy(() => import('./views/maintenance/ComingSoon'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/rutas',
        element: lazy(() => import('./views/dashboard/DashRutas'))
      },
      {
        exact: 'true',
        path: '/app/reportes/base-cruda',
        element: lazy(() => import('./views/dashboard/BaseCruda'))
      },
      {
        exact: 'true',
        path: '/app/reportes/estatus-cadenas',
        element: lazy(() => import('./views/dashboard/EstatusCadenas'))
      },
      {
        exact: 'true',
        path: '/app/portales',
        element: lazy(() => import('./views/dashboard/AccesosPortales'))
      },
      {
        exact: 'true',
        path: '/app/ejecuciones',
        element: lazy(() => import('./views/dashboard/ejecuciones'))
        // element: lazy(() => import('./views/maintenance/ComingSoon'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/anaqueles',
        element: lazy(() => import('./views/dashboard/DashAnaqueles'))
        // element: lazy(() => import('./views/maintenance/ComingSoon'))
      },
      {
        exact: 'true',
        path: '/app/widgets/widget-data',
        element: lazy(() => import('./views/widgets/WidgetData'))
      },
      {
        exact: 'true',
        path: '/app/widgets/widget-chart',
        element: lazy(() => import('./views/widgets/WidgetChart'))
      },
      {
        exact: 'true',
        path: '/layout/vertical/static',
        element: lazy(() => import('./views/page-layouts/vertical/Static'))
      },
      {
        exact: 'true',
        path: '/layout/vertical/fixed',
        element: lazy(() => import('./views/page-layouts/vertical/Fixed'))
      },

      {
        exact: 'true',
        path: '/sample-page',
        element: lazy(() => import('./views/extra/SamplePage'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/testing/salud-inventarios',
        element: lazy(() => import('./views/dashboard/DashTesting/salud-inventarios'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/testing/disponibilidad-inventarios',
        element: lazy(() => import('./views/dashboard/DashTesting/disponibilidad-inventarios'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/testing/disponibilidad-inventarios-por-dia',
        element: lazy(() => import('./views/dashboard/DashTesting/disponibilidad-inventarios-por-dia'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/testing/promotoria',
        element: lazy(() => import('./views/dashboard/DashTesting/promotoria'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/testing/promociones-muebles',
        element: lazy(() => import('./views/dashboard/DashTesting/promociones-muebles'))
      },
      {
        exact: 'true',
        path: '/app/dashboard/testing/iniciativas',
        element: lazy(() => import('./views/dashboard/DashTesting/iniciativas'))
      },
      {
        exact: 'true',
        path: '/users/user-profile',
        element: lazy(() => import('./views/users/UserProfile'))
      },
      {
        path: '*',
        exact: 'true',
        element: () => <Navigate to={BASE_URL} />
      }
    ]
  }
];

export default renderRoutes;
