import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from '../store/actions';
// import axios from '../services/axios';
import accountReducer from '../store/accountReducer';
import Loader from '../components/Loader/Loader';
import { getResponseData, usersApiServiceUrl } from '../utils/appUtils';
import { navigate } from '@reach/router';

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};



const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);
  if (!localStorage.getItem('cuenta'))
    localStorage.setItem('cuenta', decoded.user.cliente === 'Bepensa' ? 'BIB' : decoded.user.cliente);
  return decoded.user.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    const decoded = jwtDecode(serviceToken);
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('userName', decoded.user.user);
    localStorage.setItem('user', JSON.stringify(decoded.user));
    localStorage.setItem('tipoCalendario', 'NATURAL');
    if (!localStorage.getItem('cuenta')) {
      localStorage.setItem('cuenta', decoded.user.cliente === 'Bepensa' ? 'BIB' : decoded.user.cliente);
    }
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    // axios.defaults.headers.common['UserAccount'] = user.cliente;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('user');
    localStorage.removeItem('cuenta');
    localStorage.removeItem('tipoCalendario');
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('user');
    localStorage.removeItem('cuenta');
    localStorage.removeItem('tipoCalendario');
  }
});

const statusSuccess = [201, 202, 203];
const statusAlertSuccess = [301, 302, 303];
axios.interceptors.response.use(

  async (response) => {
    let { status, statusCode, message, data } = response.data;
    if (typeof message === "string") {
      let oneStatus = status ?? statusCode;
      if (statusSuccess.indexOf(+oneStatus) >= 0) {
        console.log(message);
      }
      if (statusAlertSuccess.indexOf(+oneStatus) >= 0) {
        console.log(message);
      }
    }
    return data;
  },
  async (error) => {
    console.error("Response Error: ", error);
    if (error.message === "Network Error" && !error.response) {
      console.log("Se perdió la conexión con el servidor");
      return;
    }

    let { status, data } = error.response || { status: null, data: null };

    if (status === 401) {
      alert(data.message);
      return;
    }

    if (status === 404) {
      if (data?.message) {
        console.log("No se encontró el servicio");
      }
      return;
    } else if (status >= 500) {
      console.log("Ocurrió un error durante la solicitud");
      if (data?.message) return { message: data.message, error: true };
    }

    if (data && typeof data?.detail === "string") {
      console.log(data.detail);
      return;
    }
  }
);

//ENVIA PETICION CON PATH AGREGADO
axios.interceptors.request.use(function (config) {
  // console.log('config: ', config);
  if (!localStorage.getItem('user') || localStorage.getItem('user').includes('tmpRAV2')) {
    if (!config.url.endsWith('/login')) {
      localStorage.removeItem('serviceToken');
      localStorage.removeItem('user');
      localStorage.removeItem('cuenta');
      localStorage.removeItem('tipoCalendario');
      setSession(null);
      navigate('/auth/signin');
      window.location.reload();
    }
  }

  let cuenta = localStorage.getItem('cuenta') || '';
  let ruta = localStorage.getItem('ruta') || '';
  if (ruta == '/app/dashboard/promotoria' || ruta == '/app/dashboard/rutas') {
    if (cuenta != 'Demo') cuenta = 'Bepensa-Spirits';
  }
  config.url = (config.url.endsWith('.php') || config.url.includes(':5001/')
    || config.url.includes('/restart/process')
    || config.url.includes('/genericMongoQuery/process/')) ? config.url : `${config.url}${cuenta.replaceAll(' ', '-')}`
  if (config.url?.toLowerCase().endsWith('bib')
    || config.url?.toLowerCase().endsWith('spirits')
    || config.url?.toLowerCase().endsWith('madrilena')
    || config.url?.toLowerCase().endsWith('vina')
    || config.url?.toLowerCase().endsWith('cinsa')) {
    let newData = config.data;
    const marcas_ = JSON.parse(localStorage.getItem('user'))?.marcas || [];
    const clientes_ = JSON.parse(localStorage.getItem('user'))?.clientes || [];
    if (newData
      && !['calendar', 'year_to_date',
        'cattiendas', 'promotoria',
        'conteo_tiendas', 'conteo_tiendas_general',
        'ejecuciones', 'cargasManuales',
        'repo'
      ].find(found => found === newData.type))
      if (!['proyectosCadenas', 'yearToDate',
        'runAll.php', 'loadLayout'
      ].find(found => config.url.includes(found))) {
        if (!newData.filterIn?.find(found => found.field === 'seccion')?.values.includes('tiendas')) {
          if (marcas_.length > 0)
            newData = {
              ...newData,
              filterIn: [
                ...(newData.filterIn || []),
                {
                  field: 'marca',
                  values: marcas_
                }
              ]
            };
        }
        if (clientes_.length > 0)
          newData = {
            ...newData,
            filterIn: [
              ...(newData.filterIn || []),
              {
                field: 'grupo',
                values: clientes_
              }
            ]
          };
      }
    config.data = newData;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);


  const login = async (email, password) => {
    // const response = await axios.post('/api/account/login', { email, password });
    const response = await axios.post(`${usersApiServiceUrl}/login`, { user: email, pass: password });
    // console.log('response: ', response);
    const { serviceToken } = response;
    setSession(serviceToken);
    const decoded = jwtDecode(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        user: decoded.user
      }
    });
  };

  const logout = () => {
    setSession(null);
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('user');
    localStorage.removeItem('cuenta');
    localStorage.removeItem('ruta');
    dispatch({ type: LOGOUT });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          // const response = await axios.get('/api/account/me');
          // const user = jwtDecode(serviceToken);
          const user = localStorage.getItem('user');
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user: { id: user.id, email: user.email }
            }
          });
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;