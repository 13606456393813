import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, use } from 'react-router-dom';

import { BsCheckSquareFill } from "react-icons/bs";
import { useIdleTimer } from "react-idle-timer";
import { EXTERNALS_USERS_TO_NOTIFY, middlewareApiServiceUrl } from '../../utils/appUtils';
import jwtDecode from 'jwt-decode';

import moment from 'moment';
import "moment/locale/es";

let bgColor = '#7bdff2';
let textColor = '#000';

let originUrl = window.location.origin;
originUrl = `${originUrl}/app/reportes/estatus-cadenas`;

export const CustomToast = () => {
    //const [bgColor, setBgColor] = useState('#b2ebf2');
    //const [textColor, setTextColor] = useState('#000');
    const [textCadena, setTextCadena] = useState('');
    const [textComentario, setTextComentario] = useState('');
    const location = useLocation();

    useEffect(() => {
        localStorage.removeItem('idNotActual');
    }, []);

    const valueTextos = (tipo, cadena, comentario, pActual) => {

        switch (tipo) {
            case "success":
                bgColor = '#7ae582';
                textColor = '#000';
                break;
            case "warning":
                bgColor = '#ffcc00';
                textColor = '#37474f';
                break;
            case "error":
                bgColor = '#dd2d4a';
                textColor = '#fafafa';
                break;
            default:
                bgColor = '#7bdff2';
                textColor = '#000';
                break;
        }
        setTextCadena(cadena);
        setTextComentario(comentario);
        localStorage.setItem('idNotActual', pActual);
    }

    const notificacionesTimer = 5000;
    const obtenerNotificaciones = async () => {
        const decoded = jwtDecode(localStorage.getItem('serviceToken'));
        if (EXTERNALS_USERS_TO_NOTIFY.find(found => found === decoded.user.email) || decoded.user.interno === 1) {
            let arrayNotificaciones = [];
            const body = {
                extraFieldsToGroup: [
                    "concentrado",
                    "cadena",
                    "ultimaCarga",
                    "estatus",
                    "comentario",
                    "tipo"
                ],
                type: "year_to_date"
            };
            let response = await axios.post(`${middlewareApiServiceUrl}/genericMongoQuery/groupBySeveralFields`, body);

            const pathLocation = location.pathname;
            const isFirstFiveMonthDays = parseInt(moment(new Date()).format('DD')) <= 5;
            if (isFirstFiveMonthDays
                && pathLocation.endsWith('base-cruda'))
                arrayNotificaciones.push({ cadena: 'Base Cruda', comentario: 'La información se encuentra en ajustes debido a las actualizaciones del portal.', tipo: 'warning' });

            response?.map(item => {
                if (item.comentario != null && item.comentario != '') { arrayNotificaciones.push({ cadena: item.cadena, comentario: item.comentario, tipo: item.tipo }); }
            });
            let idNotActual = localStorage.getItem('idNotActual');
            if (idNotActual == null) {
                if (arrayNotificaciones.length >= 1) { valueTextos(arrayNotificaciones[0].tipo, arrayNotificaciones[0].cadena, arrayNotificaciones[0].comentario, 1); }
            } else {
                let posicionActual = idNotActual;
                if (posicionActual < arrayNotificaciones.length) {
                    posicionActual++;
                    if (posicionActual > arrayNotificaciones.length) {
                        valueTextos(arrayNotificaciones[0].tipo, arrayNotificaciones[0].cadena, arrayNotificaciones[0].comentario, 0);
                    } if (posicionActual == arrayNotificaciones.length) {
                        valueTextos(arrayNotificaciones[0].tipo, arrayNotificaciones[0].cadena, arrayNotificaciones[0].comentario, 0);
                    } else { valueTextos(arrayNotificaciones[posicionActual].tipo, arrayNotificaciones[posicionActual].cadena, arrayNotificaciones[posicionActual].comentario, posicionActual); }
                } else { localStorage.setItem('idNotActual', 0); }
            }

            if (arrayNotificaciones.length >= 1 && textComentario?.length > 0) {
                let cuenta = localStorage.getItem('cuenta');
                if (cuenta == 'Madrilena') cuenta = 'Madrileña';
                // getBaseCrudaNotification();
                toast(
                    (t) => (
                        <span>
                            <b>{`${cuenta}${textCadena.length === 0 ? '' : ` - ${textCadena}`}`}</b>
                            <br></br>
                            {textComentario}
                            <br></br>
                            {textCadena !== 'Base Cruda' &&
                                <div className='d-flex justify-content-end'>
                                    <a href={originUrl}><b className={'text-' + textColor}>Ver detalle ...</b></a>
                                </div>
                            }
                        </span>
                    )
                );
            }
        }
    };

    const onAction = () => {
        reset();
    };

    const { reset } = useIdleTimer({
        name: "notificaciones-timer",
        crossTab: {
            type: "localStorage",
            emitOnAllTabs: true,
        },
        timeout: notificacionesTimer,
        onIdle: obtenerNotificaciones,
        onAction,
    });

    return (
        <>
            <Toaster
                position='bottom-right'
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: bgColor,
                        color: textColor,
                    },
                }}
            />
        </>
    );
};

