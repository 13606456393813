let menuItems = {};
let cuenta = localStorage.getItem('cuenta');
const decoded = JSON.parse(localStorage.getItem('user') || '{}');
if (cuenta == 'Demo') {
  menuItems = {
    items: [
      {
        id: 'open-close',
        title: '',
        type: 'menu-icon',
        icon: 'feather icon-menu',
      },
      {
        id: 'navigation',
        title: 'Navegación',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Reportes',
            type: 'collapse',
            icon: 'feather icon-activity',
            children: [
              {
                id: 'sales',
                title: 'Ventas',
                type: 'item',
                url: '/app/dashboard/ventas',
              },
              {
                id: 'VentaCero',
                title: 'Venta Cero',
                type: 'item',
                url: '/app/dashboard/venta-cero',
              },
              {
                id: 'Disp',
                title: 'Disp. Inventarios',
                type: 'item',
                url: '/app/dashboard/testing/disponibilidad-inventarios',
                // url: '/app/dashboard/disponibilidad-inventarios',
              },
              {
                id: 'Scorecard',
                title: 'Scorecard',
                type: 'item',
                url: '/app/dashboard/scorecard-VSPT',
              },
              {
                id: 'estatus',
                title: 'Estatus cadenas',
                type: 'item',
                url: '/app/reportes/estatus-cadenas',
              },
              // {
              //   id: 'Promociones-Muebles-testing',
              //   title: 'Promociones',
              //   type: 'item',
              //   url: '/app/dashboard/testing/promociones-muebles',
              // },
              {
                id: 'Desempeño-Iniciativas',
                title: 'Iniciativas',
                type: 'item',
                url: '/app/dashboard/testing/iniciativas',
              },
            ]
          },
        ]
      },
    ]
  };
} else if (cuenta == 'Cinsa') {
  menuItems = decoded?.email?.endsWith('evolve.com.mx') && decoded?.interno == 1
    ? {
      items: [
        {
          id: 'open-close',
          title: '',
          type: 'menu-icon',
          icon: 'feather icon-menu',
        },
        {
          id: 'navigation',
          title: 'Navegación',
          type: 'group',
          icon: 'icon-navigation',
          children: [
            {
              id: 'dashboard',
              title: 'Reportes',
              type: 'collapse',
              icon: 'feather icon-activity',
              children: [
                {
                  id: 'sales',
                  title: 'Ventas',
                  type: 'item',
                  url: '/app/dashboard/ventas',
                },
                {
                  id: 'estatus',
                  title: 'Estatus cadenas',
                  type: 'item',
                  url: '/app/reportes/estatus-cadenas',
                },
                {
                  id: 'Base',
                  title: 'Base Cruda',
                  type: 'item',
                  url: '/app/reportes/base-cruda',
                },
              ]
            },
            {
              id: 'admin',
              title: 'Administración',
              type: 'collapse',
              icon: 'feather icon-package',
              children: [
                {
                  id: 'data-webs',
                  title: 'Portáles',
                  type: 'item',
                  url: '/app/portales',
                  breadcrumbs: false
                },
                {
                  id: 'executions',
                  title: 'Ejecuciones',
                  type: 'item',
                  url: '/app/ejecuciones',
                  breadcrumbs: false
                },
              ]
            },
          ]
        },
      ]
    }
    : {
      items: [
        {
          id: 'open-close',
          title: '',
          type: 'menu-icon',
          icon: 'feather icon-menu',
        },
        {
          id: 'navigation',
          title: 'Navegación',
          type: 'group',
          icon: 'icon-navigation',
          children: [
            {
              id: 'dashboard',
              title: 'Reportes',
              type: 'collapse',
              icon: 'feather icon-activity',
              children: [
                {
                  id: 'sales',
                  title: 'Ventas',
                  type: 'item',
                  url: '/app/dashboard/ventas',
                },
                {
                  id: 'estatus',
                  title: 'Estatus cadenas',
                  type: 'item',
                  url: '/app/reportes/estatus-cadenas',
                },
                {
                  id: 'Base',
                  title: 'Base Cruda',
                  type: 'item',
                  url: '/app/reportes/base-cruda',
                },
              ]
            },
          ]
        },
      ]
    };
} else if (decoded?.email?.endsWith('evolve.com.mx') && decoded?.interno == 1) {
  menuItems = {
    items: [
      {
        id: 'open-close',
        title: '',
        type: 'menu-icon',
        icon: 'feather icon-menu',
      },
      {
        id: 'navigation',
        title: 'Navegación',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Reportes',
            type: 'collapse',
            icon: 'feather icon-activity',
            children: [
              {
                id: 'sales',
                title: 'Ventas',
                type: 'item',
                url: '/app/dashboard/ventas',
              },
              {
                id: 'Inventarios',
                title: 'Disp. de Inventarios',
                type: 'item',
                url: '/app/dashboard/inventory',
              },
              {
                id: 'Promotoria',
                title: 'Promotoria',
                type: 'item',
                url: '/app/dashboard/promotoria',
              },
              {
                id: 'Rutas',
                title: 'Rutas',
                type: 'item',
                url: '/app/dashboard/rutas',
              },
              {
                id: 'Salud',
                title: 'Salud Inventarios',
                type: 'item',
                url: '/app/dashboard/salud-inventarios',
              },
              {
                id: 'VentaCero',
                title: 'Venta Cero',
                type: 'item',
                url: '/app/dashboard/venta-cero',
              },
              {
                id: 'Scorecard',
                title: 'Scorecard',
                type: 'item',
                url: '/app/dashboard/scorecard-VSPT',
              },
              {
                id: 'Base',
                title: 'Base Cruda',
                type: 'item',
                url: '/app/reportes/base-cruda',
              },
              {
                id: 'estatus',
                title: 'Estatus cadenas',
                type: 'item',
                url: '/app/reportes/estatus-cadenas',
              },
              {
                id: 'comercial',
                title: 'Tablero comercial',
                type: 'item',
                url: '/app/reportes/tablero-comercial',
              },
              {
                id: 'cobertura',
                title: 'Desempeño geográfico',
                type: 'item',
                url: '/app/reportes/analisis-cobertura',
              },
            ]
          },
          {
            id: 'admin',
            title: 'Administración',
            type: 'collapse',
            icon: 'feather icon-package',
            children: [
              {
                id: 'data-webs',
                title: 'Portáles',
                type: 'item',
                url: '/app/portales',
                breadcrumbs: false
              },
              {
                id: 'executions',
                title: 'Ejecuciones',
                type: 'item',
                url: '/app/ejecuciones',
                breadcrumbs: false
              },
            ]
          },
          {
            id: 'testing',
            title: 'Testing',
            type: 'collapse',
            icon: 'feather icon-cpu',
            children: [
              {
                id: 'Salud-testing',
                title: 'Salud Inventarios',
                type: 'item',
                url: '/app/dashboard/testing/salud-inventarios',
              },
              {
                id: 'Disponibilidad-testing',
                title: 'Disponibilidad Inventarios',
                type: 'item',
                url: '/app/dashboard/testing/disponibilidad-inventarios',
              },
              {
                id: 'Disponibilidad-testing-dia',
                title: 'Disponibilidad Inventarios por Día',
                type: 'item',
                url: '/app/dashboard/testing/disponibilidad-inventarios-por-dia',
              },
              {
                id: 'Promotoria-testing',
                title: 'Promotoría',
                type: 'item',
                url: '/app/dashboard/testing/promotoria',
              },
              // {
              //   id: 'Promociones-Muebles-testing',
              //   title: 'Promociones',
              //   type: 'item',
              //   url: '/app/dashboard/testing/promociones-muebles',
              // },
              {
                id: 'Desempeño-Iniciativas',
                title: 'Iniciativas',
                type: 'item',
                url: '/app/dashboard/testing/iniciativas',
              },
            ]
          },
        ]
      },
    ]
  };
} else {
  menuItems = {
    items: [
      {
        id: 'open-close',
        title: '',
        type: 'menu-icon',
        icon: 'feather icon-menu',
      },
      {
        id: 'navigation',
        title: 'Navegación',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Reportes',
            type: 'collapse',
            icon: 'feather icon-activity',
            children: [
              {
                id: 'sales',
                title: 'Ventas',
                type: 'item',
                url: '/app/dashboard/ventas',
              },
              {
                id: 'Inventarios',
                title: 'Disp. de Inventarios',
                type: 'item',
                url: '/app/dashboard/inventory',
              },
              {
                id: 'Promotoria',
                title: 'Promotoria',
                type: 'item',
                url: '/app/dashboard/promotoria',
              },
              {
                id: 'Rutas',
                title: 'Rutas',
                type: 'item',
                url: '/app/dashboard/rutas',
              },
              {
                id: 'Salud',
                title: 'Salud Inventarios',
                type: 'item',
                url: '/app/dashboard/salud-inventarios',
              },
              {
                id: 'VentaCero',
                title: 'Venta Cero',
                type: 'item',
                url: '/app/dashboard/venta-cero',
              },
              {
                id: 'Scorecard',
                title: 'Scorecard',
                type: 'item',
                url: '/app/dashboard/scorecard-VSPT',
              },
              {
                id: 'Base',
                title: 'Base Cruda',
                type: 'item',
                url: '/app/reportes/base-cruda',
              },
              {
                id: 'estatus',
                title: 'Estatus cadenas',
                type: 'item',
                url: '/app/reportes/estatus-cadenas',
              },
              {
                id: 'comercial',
                title: 'Tablero comercial',
                type: 'item',
                url: '/app/reportes/tablero-comercial',
              },
              {
                id: 'cobertura',
                title: 'Desempeño geográfico',
                type: 'item',
                url: '/app/reportes/analisis-cobertura',
              }
            ]
          },
          {
            id: 'admin',
            title: 'Administración',
            type: 'collapse',
            icon: 'feather icon-package',
            children: [
              {
                id: 'data-webs',
                title: 'Portáles',
                type: 'item',
                url: '/app/portales',
                breadcrumbs: false
              },
            ]
          }
        ]
      },
    ]
  };
}

export default menuItems;
