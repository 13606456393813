import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

export const TitleReporte = () => {
    const decoded = JSON.parse(localStorage.getItem('user') || '{}');
    const handlechange = (opt) => {
        let value = opt.value.value;
        // if (value.includes('epensa'))
        //   value = ''
        console.log('value: ', value);
        localStorage.setItem('cuenta', value)
        window.location.reload();
    }

    const handlechangeTipoCalendario = (opt) => {
        let value = opt.value.value;
        // if (value.includes('epensa'))
        //   value = ''
        console.log('value: ', value);
        localStorage.setItem('tipoCalendario', value);
        window.location.reload();
    }

    const location = useLocation();
    const crumbs = location.pathname;
    let title = "";
    let flagCruda = false;
    let showCuenta = localStorage.getItem('cuenta');
    let showTipoCalendario = localStorage.getItem('tipoCalendario');
    if (!showTipoCalendario) {
        showTipoCalendario = 'NATURAL';
        localStorage.setItem('tipoCalendario', showTipoCalendario);
        window.location.reload();
    }
    switch (crumbs) {
        case '/app/dashboard/ventas':
            title = "Desempeño de ventas";
            localStorage.setItem('ruta', '/app/dashboard/ventas');
            break;
        case '/app/dashboard/testing/disponibilidad-inventarios':
            title = "Disponibilidad de inventarios";
            localStorage.setItem('ruta', '/app/dashboard/testing/inventory');
            break;
        case '/app/dashboard/testing/promociones-muebles':
            title = "Promociones - Activaciones";
            localStorage.setItem('ruta', '/app/dashboard/testing/promociones-muebles');
            break;
        case '/app/dashboard/testing/iniciativas':
            title = "Iniciativas";
            localStorage.setItem('ruta', '/app/dashboard/iniciativas');
            break;
        case '/app/dashboard/inventory':
            title = "Disponibilidad de inventarios";
            localStorage.setItem('ruta', '/app/dashboard/inventory');
            break;
        case '/app/dashboard/venta-cero':
            title = "Venta Cero";
            localStorage.setItem('ruta', '/app/dashboard/venta-cero');
            break;
        case '/app/dashboard/promotoria':
            title = "Desempeño de promotoría";
            localStorage.setItem('ruta', '/app/dashboard/promotoria');
            break;
        case '/app/dashboard/rutas':
            title = "Ventas por rutas";
            localStorage.setItem('ruta', '/app/dashboard/rutas');
            break;
        case '/app/dashboard/salud-inventarios':
            title = "Salud de Inventarios";
            localStorage.setItem('ruta', '/app/dashboard/salud-inventarios');
            break;
        case '/app/dashboard/disponibilidad-inventarios':
            title = "Disponibilidad de Inventarios";
            localStorage.setItem('ruta', '/app/dashboard/disponibilidad-inventarios');
            break;
        case '/app/dashboard/scorecard-VSPT':
            title = "Scorecard Sell Out";
            localStorage.setItem('ruta', '/app/dashboard/scorecard-VSPT');
            break;
        case '/app/dashboard/general':
            // title = "Reporte General";
            title = "Desempeño de ventas";
            localStorage.setItem('ruta', '/app/dashboard/general');
            break;
        case '/app/reportes/base-cruda':
            title = "Base Cruda";
            localStorage.setItem('ruta', '/app/reportes/base-cruda');
            flagCruda = true;
            if (showCuenta === 'Bepensa-Spirits') {
                localStorage.setItem('cuenta', 'BIB');
                window.location.reload();
            }
            break;
        case '/app/reportes/estatus-cadenas':
            title = "Estatus Cadenas";
            flagCruda = true;
            localStorage.setItem('ruta', '/app/reportes/estatus-cadenas');
            if (showCuenta === 'Bepensa-Spirits') {
                localStorage.setItem('cuenta', 'BIB');
                window.location.reload();
            }
            break;
        case '/app/portales':
            title = "Portáles";
            localStorage.setItem('ruta', '/app/portales');
            break;
        case '/app/reportes/tablero-comercial':
            title = "Tablero comercial";
            localStorage.setItem('ruta', '/app/reportes/tablero-comercial');
            break;
        case '/app/reportes/analisis-cobertura':
            title = "Desempeño geográfico";
            localStorage.setItem('ruta', '/app/reportes/analisis-cobertura');
            break;
        case '/app/ejecuciones':
            title = "Ejecuciones";
            localStorage.setItem('ruta', '/app/ejecuciones');
            flagCruda = true;
            if (showCuenta === 'Bepensa-Spirits') {
                localStorage.setItem('cuenta', 'BIB');
                window.location.reload();
            }
            break;
        default:
            const defTitleParts = crumbs.split('/');
            title = `testing-${defTitleParts[defTitleParts.length - 1]}`;
            localStorage.setItem('ruta', `/app/dashboard/testing/${title}`);
            break;
    }

    let ruta = localStorage.getItem('ruta') || '';
    let promotoriaRutas = 0;
    let showTipoCalendarioByPath = 0;
    let flagEstatusCadenas = 0;
    if (ruta == '/app/dashboard/promotoria' || ruta == '/app/dashboard/rutas') {
        if (showCuenta != 'Demo') {
            showCuenta = 'Bepensa-Spirits';
            promotoriaRutas = 1;
        }
    }
    if ((['/app/dashboard/inventory', '/app/dashboard/inventory', '/app/dashboard/venta-cero', '/app/dashboard/disponibilidad-inventarios', '/app/dashboard/scorecard-VSPT'].find(found => found === ruta)
        || ruta.includes('testing')) && ruta !== '/app/dashboard/testing/testing-promotoria' && ruta !== '/app/dashboard/testing/iniciativas') {
        showTipoCalendarioByPath = 1;
    }
    //if (ruta == '/app/reportes/estatus-cadenas'){flagEstatusCadenas=1;}

    return (
        <>
            <h5 className='mt-2 text-white fw-light'>{title}</h5>
            {promotoriaRutas == 0 ?
                ['BIB', 'Bepensa-Spirits', 'Madrilena', 'Sol y Vina', 'Cinsa'].find(found => found === localStorage.getItem('cuenta')) &&
                <>
                    <Select
                        className="ms-5 basic-select text-primary-emphasis"
                        name={"canal"}
                        classNamePrefix={"select"}
                        onChange={(selectedFilter) => {
                            const fixedFilter = {
                                name: 'canal',
                                value: selectedFilter
                            };
                            handlechange(fixedFilter);
                        }}
                        placeholder={'Cuenta'}
                        options={
                            !flagCruda
                                ? (decoded?.email?.endsWith('evolve.com.mx')
                                    && decoded?.interno == 1)
                                    ? [
                                        {
                                            label: 'BIB',
                                            value: 'BIB'
                                        }, {
                                            label: 'Madrileña',
                                            value: 'Madrilena'
                                        },
                                        {
                                            label: 'Bepensa-Spirits',
                                            value: 'Bepensa-Spirits'
                                        }, {
                                            label: 'Sol y Viña',
                                            value: 'Sol y Vina'
                                        }, {
                                            label: 'Cinsa',
                                            value: 'Cinsa'
                                        }
                                    ]
                                    : [
                                        {
                                            label: 'BIB',
                                            value: 'BIB'
                                        }, {
                                            label: 'Madrileña',
                                            value: 'Madrilena'
                                        },
                                        {
                                            label: 'Bepensa-Spirits',
                                            value: 'Bepensa-Spirits'
                                        },
                                    ]
                                : (decoded?.email?.endsWith('evolve.com.mx')
                                    && decoded?.interno == 1)
                                    ? [
                                        {
                                            label: 'BIB',
                                            value: 'BIB'
                                        }, {
                                            label: 'Madrileña',
                                            value: 'Madrilena'
                                        }, {
                                            label: 'Sol y Viña',
                                            value: 'Sol y Vina'
                                        }, {
                                            label: 'Cinsa',
                                            value: 'Cinsa'
                                        }
                                    ]
                                    : [
                                        {
                                            label: 'BIB',
                                            value: 'BIB'
                                        }, {
                                            label: 'Madrileña',
                                            value: 'Madrilena'
                                        },
                                    ]
                        }
                        closeMenuOnSelect={true}
                        maxMenuHeight={200}
                    />
                    <b className='ms-3'>{showCuenta}</b>
                </>
                : <b className='ms-5'>{showCuenta}</b>
            }
            {
                ((!['Demo'].find(found => found === localStorage.getItem('cuenta')) && showTipoCalendarioByPath === 1)
                    && decoded?.email?.endsWith('evolve.com.mx')
                    && decoded?.interno == 1) &&
                <>
                    <Select
                        className="ms-5 basic-select text-primary-emphasis"
                        name={"tipoCalendario"}
                        classNamePrefix={"select"}
                        onChange={(selectedFilter) => {
                            const fixedFilter = {
                                name: 'tipoCalendario',
                                value: selectedFilter
                            };
                            handlechangeTipoCalendario(fixedFilter);
                        }}
                        placeholder={'Tipo de Calendario'}
                        options={
                            [
                                {
                                    label: 'NATURAL',
                                    value: 'NATURAL'
                                }, {
                                    label: 'WALMART',
                                    value: 'WALMART'
                                },
                            ]
                        }
                        closeMenuOnSelect={true}
                        maxMenuHeight={200}
                    />
                    <b className='ms-3'>{showTipoCalendario}</b>
                </>
            }
        </>
    );

};